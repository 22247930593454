import { all, fork } from 'redux-saga/effects';

// Add more saga functions here

// Auth
import authSaga from 'store/auth/saga';

// Checkout
import checkoutSaga from 'store/checkout/saga';

export default function* rootSaga() {
	yield all([fork(authSaga), fork(checkoutSaga)]);
}
