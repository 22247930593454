import React from 'react';

import { Routes, Route, Link, Navigate, Outlet } from 'react-router-dom';

import PropTypes from 'prop-types';

import UserState from 'backend/user_session';
import ShopCart from 'backend/shop_cart';

export const ProtectedRoute = ({ redirectPath = '/login', children }) => {
	if (!UserState.isAuthenticated()) {
		return <Navigate to={redirectPath} replace />;
	}

	return children ? children : <Outlet />;
};

ProtectedRoute.propTypes = {
	redirectPath: PropTypes.string,
	children: PropTypes.any
};

export const OrderWizardRoute = ({ redirectPath = '/', children }) => {
	if (ShopCart.isEmpty() || !UserState.hasValidOrderState()) {
		return <Navigate to={redirectPath} replace />;
	}

	return children ? children : <Outlet />;
};

OrderWizardRoute.propTypes = {
	redirectPath: PropTypes.string,
	children: PropTypes.any
};
