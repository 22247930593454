import React from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PageHeaderMeta = props => {
	let location = useLocation();
	const { t, i18n } = useTranslation();

	const title = props.page_title ? props.page_title + ' | NEF-NEF B2B' : 'NEF-NEF B2B | ' + t('home_page_title');
	const url = 'https://b2b.nef-nef.gr' + location.pathname;

	const renderMetaKeywords = () => {
		if (props.meta_keywords != null) {
			return <meta name="keywords" content={props.meta_keywords} />;
		}

		return null;
	};

	const renderMetaDescription = () => {
		let metaDescription = t('default_meta_description');

		if (props.meta_description != null) {
			metaDescription = props.meta_description;
		}

		return <meta name="description" content={metaDescription} />;
	};

	return (
		<Helmet>
			<html lang={i18n.language} dir={i18n.dir()} />
			<meta charSet="utf-8" />
			<title>{title}</title>
			<link rel="canonical" href={url} />
			{renderMetaKeywords()}
			{renderMetaDescription()}
		</Helmet>
	);
};

PageHeaderMeta.propTypes = {
	page_title: PropTypes.string,
	meta_keywords: PropTypes.string,
	meta_description: PropTypes.string
};

export default PageHeaderMeta;
