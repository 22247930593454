import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { pushHistoryStateAction } from 'store/history/actions';

import { clearNotifications } from 'components/common/ToastMessages';

export default function HistoryMonitor() {
	let location = useLocation();
	const dispatch = useDispatch();

	useEffect(() => {
		// Clear any opened notifications when transitioning to another page/component
		clearNotifications();
		//console.log("Loc change: " + location.pathname);
		dispatch(pushHistoryStateAction(location.pathname));
	}, [dispatch, location]);

	return null;
}
