const initialState = {
	error: '',
	cartMsg: '',
	totalCount: 0,
	orderResponse: {},
	shipping: {},
	payment: {},
	order: {},
	newsletterFlag: false,
	loading: false,
	showCartWidget: false
};

const checkoutReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'add-to-cart':
			state = { ...state, loading: true };
			break;
		case 'restore-cart':
			state = { ...state, loading: true };
			break;
		case 'remove-from-cart':
			state = { ...state, loading: true };
			break;
		case 'open-cart-widget':
			state = { ...state, showCartWidget: action.bool };
			break;
		case 'cart-update-success':
			state = { ...state, cartMsg: action.message, totalCount: action.count, loading: false };
			break;
		case 'checkout-error':
			state = { ...state, error: action.error, loading: false };
			break;
		case 'shipping-selected':
			state = { ...state, shipping: action.shipping, loading: false };
			break;
		case 'payment-selected':
			state = { ...state, payment: action.payment, loading: false };
			break;
		case 'receive-newsletters-selected':
			state = { ...state, newsletterFlag: action.newsletterFlag };
			break;
		case 'order-changed':
			state = { ...state, order: action.order, loading: false };
			break;
		case 'order-completed':
			state = { ...state, order: action.order, loading: true };
			break;
		case 'order-complete-success':
			state = { ...state, orderResponse: action.response, loading: false };
			break;
		case 'clear-messages':
			state = { ...state, error: '', cartMsg: '', orderResponse: {} };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default checkoutReducer;
