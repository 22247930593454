export const addToCartAction = args => {
	return {
		type: 'add-to-cart',
		args
	};
};

export const restoreCartAction = args => {
	return {
		type: 'restore-cart',
		args
	};
};

export const removeFromCartAction = args => {
	return {
		type: 'remove-from-cart',
		args
	};
};

export const clearCartAction = () => {
	return {
		type: 'clear-cart'
	};
};

export const cartUpdateSuccessAction = (message, count) => {
	return {
		type: 'cart-update-success',
		message,
		count
	};
};

export const checkoutErrorAction = error => {
	return {
		type: 'checkout-error',
		error
	};
};

export const shippingSelectAction = shipping => {
	return {
		type: 'shipping-selected',
		shipping
	};
};

export const paymentSelectAction = payment => {
	return {
		type: 'payment-selected',
		payment
	};
};

export const receiveNewslettersSelectAction = newsletterFlag => {
	return {
		type: 'receive-newsletters-selected',
		newsletterFlag
	};
};

export const orderChangeAction = order => {
	return {
		type: 'order-changed',
		order
	};
};

export const orderCompleteAction = order => {
	return {
		type: 'order-completed',
		order
	};
};

export const orderCompleteSuccessAction = response => {
	return {
		type: 'order-complete-success',
		response
	};
};

export const checkoutClearMessages = () => {
	return {
		type: 'clear-messages'
	};
};

export const openCartWidgetAction = bool => {
	return {
		type: 'open-cart-widget',
		bool
	};
};
