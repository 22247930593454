import { get, post, put, del, apiBaseUrl, get_sp, get_file } from 'backend/api_helper';

// customer auth calls
export const api_login = data => post(apiBaseUrl() + '/customers/login', data, false);
export const api_logout = data => post(apiBaseUrl() + '/customers/logout', data, false);
export const api_refreshToken = data => post(apiBaseUrl() + '/customers/refreshtoken', data, false);
// customer auth calls

// sales person
export const api_salesperson_login = data => post(apiBaseUrl() + '/salespersons/login', data, false);
export const api_salesperson_logout = data => post(apiBaseUrl() + '/salespersons/logout', data, false);

export async function api_getSalesPersonCustomers() {
	const url = apiBaseUrl() + '/salespersoncustomersmap/getlinkedcustomers';
	const response = await get_sp(url);
	return response;
}

// sales person

export async function api_getProfile() {
	const url = apiBaseUrl() + '/customers/getprofile';
	const response = await get(url);
	return response;
}

export async function api_getBranches() {
	const url = apiBaseUrl() + '/customerbranches/getforcustomer';
	const response = await get(url);
	return response;
}

export async function api_getProductByUrl(key) {
	const url = apiBaseUrl() + '/products/getbyurl?url=' + encodeURIComponent(key);
	const response = await get(url, true);
	return response;
}

export async function api_getCountries() {
	const url = apiBaseUrl() + '/countries/getallfront';
	const response = await get(url, true, false);
	return response;
}

export async function api_getCountryRegions(cid) {
	const url = apiBaseUrl() + '/countryregions/getbycountry?cid=' + cid;
	const response = await get(url, true, false);
	return response;
}

export async function api_findTakeAwayStores(cid = 0, rid = 0) {
	var suffix = '';

	if (cid > 0) suffix += '?cid=' + cid;
	if (rid > 0) suffix += (suffix.length > 0 ? '&' : '?') + 'rid=' + rid;

	const url = apiBaseUrl() + '/takeawaystores/findfront' + suffix;
	const response = await get(url, true, false);
	return response;
}

export async function api_findTakeAwayStoresGrouped(cid = 0) {
	var suffix = '';

	if (cid > 0) suffix += '?cid=' + cid;

	const url = apiBaseUrl() + '/takeawaystores/findfrontgroupedlookup' + suffix;
	const response = await get(url, true, false);
	return response;
}

export async function api_getShippingTypes() {
	const url = apiBaseUrl() + '/shippingtypes/getallfront';
	const response = await get(url, true, false);
	return response;
}

export async function api_getPaymentTypes() {
	const url = apiBaseUrl() + '/paymenttypes/getallfront';
	const response = await get(url, true, false);
	return response;
}

export async function api_getPaymentTypesByShipping(sid) {
	const url = apiBaseUrl() + '/paymenttypes/getbyshippingtype?sid' + sid;
	const response = await get(url, true, false);
	return response;
}

export async function api_saveOrder(record) {
	const url = apiBaseUrl() + '/orders/save';
	const response = await post(url, record);
	return response;
}

export async function api_getContentPageByCode(key) {
	const url = apiBaseUrl() + '/contentpages/getbycode?code=' + key;
	const response = await get(url, true, false);
	return response;
}

export async function api_getContentPageMenuLinks(record) {
	const url = apiBaseUrl() + '/contentpages/getmenulinks?args=' + encodeURIComponent(JSON.stringify(record));
	const response = await get(url, true, false);
	return response;
}

export async function api_getContentPageByUrl(key) {
	const url = apiBaseUrl() + '/contentpages/getbyurl?url=' + encodeURIComponent(key);
	const response = await get(url, true, false);
	return response;
}

export async function api_getProductColors(key) {
	const url = apiBaseUrl() + '/productdimensions/getproductvalues?dt=2&pid=' + key;
	const response = await get(url, true);
	return response;
}

export async function api_getMainMenu() {
	const url = apiBaseUrl() + '/menuitems/getmainmenu';
	const response = await get(url, true, false);
	return response;
}

export async function api_getCategoryByUrl(key) {
	const url = apiBaseUrl() + '/categories/getbyurl?url=' + encodeURIComponent(key);
	const response = await get(url, true, false);
	return response;
}

export async function api_getCategoryById(key) {
	const url = apiBaseUrl() + '/categories/getbyid?id=' + encodeURIComponent(key);
	const response = await get(url, true, false);
	return response;
}

export async function api_getByParentCategoryId(key) {
	const url = apiBaseUrl() + '/categories/getByParentCategoryId?parentid=' + encodeURIComponent(key);
	const response = await get(url, true, false);
	return response;
}

export async function api_getProductsByCategoryId(key, filter = '', sort = '', page = 0, psize = 0) {
	var qstr = '?id=' + encodeURIComponent(key);

	if (filter != null && filter.length > 0) {
		qstr += '&q=' + encodeURIComponent(filter);
	}
	if (sort != null && sort.length > 0) {
		qstr += '&sort=' + encodeURIComponent(sort);
	}
	if (page != null && page > 0) {
		qstr += '&page=' + encodeURIComponent(page);
	}
	if (psize != null && psize > 0) {
		qstr += '&psize=' + encodeURIComponent(psize);
	}

	const url = apiBaseUrl() + '/products/getbycategoryid' + qstr;
	const response = await get(url, true);
	return response;
}

export async function api_searchProducts(keyword, statusId = 0, filter = '', sort = '', page = 0, psize = 0) {
	var qstr = '?key=';

	if (keyword != null && keyword.length > 0) {
		qstr += encodeURIComponent(keyword);
	}
	if (statusId != null && statusId > 0) {
		qstr += '&stids=' + statusId;
	}
	if (filter != null && filter.length > 0) {
		qstr += '&q=' + encodeURIComponent(filter);
	}
	if (sort != null && sort.length > 0) {
		qstr += '&sort=' + encodeURIComponent(sort);
	}
	if (page != null && page > 0) {
		qstr += '&page=' + encodeURIComponent(page);
	}
	if (psize != null && psize > 0) {
		qstr += '&psize=' + encodeURIComponent(psize);
	}

	const url = apiBaseUrl() + '/products/searchwithfilters' + qstr;
	const response = await get(url, true);
	return response;
}

export async function api_getOrdersByCustomer(id) {
	const url = apiBaseUrl() + '/orders/getbycustomerid?id=' + id;
	const response = await get(url, id);
	return response;
}

export async function api_getOrderProductsByOrder(id) {
	const url = apiBaseUrl() + '/NefNefB2B/OrderLines/GetOrderLines?id=' + id + '&prc=1';
	const response = await get(url, id);
	return response;
}

export async function api_getProductDimensions(key) {
	const url = apiBaseUrl() + '/productdimensions/getdimensions?pid=' + key;
	const response = await get(url, true, false);
	return response;
}

export async function api_resetPasswordRequest(email) {
	const url = apiBaseUrl() + '/customers/resetpasswordrequest?email=' + email;
	const response = await get(url, true, false);
	return response;
}

export async function api_validatePasswordResetRequest(guid) {
	const url = apiBaseUrl() + '/customers/validatepasswordresetrequest?guid=' + guid;
	const response = await get(url, true, false);
	return response;
}

export async function api_resetPassword(args) {
	const url = apiBaseUrl() + '/customers/resetpassword';
	const response = await post(url, args, false);
	return response;
}

export async function api_getProductsFromSavedOrder(id) {
	const url = apiBaseUrl() + '/products/getfromsavedorder?oid=' + id;
	const response = await get(url, id);
	return response;
}

export async function api_getStatusByUrl(key) {
	const url = apiBaseUrl() + '/statuses/getbyurl?url=' + encodeURIComponent(key);
	const response = await get(url, true, false);
	return response;
}

export async function api_impersonateCustomer(guid) {
	const url = apiBaseUrl() + '/customers/impersonatecustomer?guid=' + guid;
	const response = await get(url, true, false);
	return response;
}

export async function api_createImpersonation(key) {
	const url = apiBaseUrl() + '/customers/createcustomerimpersonation?customerId=' + key;
	const response = await get_sp(url);
	return response;
}

export async function api_getProductsExcel() {
	const url = apiBaseUrl() + '/nefnefb2b/export/getproducts';
	await get_file(url);
}

export async function api_getCustomMessageByCode(code) {
	const url = apiBaseUrl() + '/custommessages/getbycode?code=' + code;
	const response = await get(url, false, false);
	return response;
}

export async function api_getBannersByBannerGroupName(key) {
	const url = apiBaseUrl() + '/banners/getbybannergroupname?bannergroupname=' + encodeURIComponent(key);
	const response = await get(url, true, false);
	return response;
}

export async function api_getProductsByIds(ids) {
	const url = apiBaseUrl() + '/products/getbyproductsids?productsids=' + encodeURIComponent(ids);
	const response = await get(url, true);
	return response;
}

export async function api_resetPasswordRequest_salesPerson(email) {
	const url = apiBaseUrl() + '/salespersons/resetpasswordrequest?email=' + email;
	const response = await get(url, true, false);
	return response;
}

export async function api_resetPassword_salesPerson(args) {
	const url = apiBaseUrl() + '/salespersons/resetpassword';
	const response = await post(url, args, false);
	return response;
}

export async function api_deleteDraftOrder(orderUID) {
	const url = apiBaseUrl() + '/orders/deletedraft';
	const response = await post(url, {
		uid: orderUID
	});
	return response;
}

export async function api_getPendingOrderDetails() {
	const url = apiBaseUrl() + '/NefNefB2B/Orders/GetPendingOrderDetails';
	const response = await get(url);
	return response;
}

export async function api_getTopCategories() {
	const url = apiBaseUrl() + '/categories/GetTopCategories';
	const response = await get(url, true, false);
	return response;
}
