import React, { Suspense, lazy } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ProtectedRoute } from './routes';

import HistoryMonitor from 'components/common/HistoryMonitor';
import Loader from 'components/common/Loader';
import PageHeaderMeta from 'components/common/PageHeaderMeta';

import './scss/App.scss';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Lazy load page components
const Login = lazy(() => import('./pages/Login'));
const Logout = lazy(() => import('./pages/Logout'));
const Home = lazy(() => import('./pages/Home'));
const UserDashboard = lazy(() => import('./pages/UserDashboard'));
const OrderCreated = lazy(() => import('./pages/OrderCreated'));
const OrderSaved = lazy(() => import('./pages/OrderSaved'));
const OrderDetails = lazy(() => import('./pages/OrderDetails'));
const Checkout = lazy(() => import('./pages/Checkout'));
const ContentPage = lazy(() => import('./pages/ContentPage'));
const CategoryList = lazy(() => import('./pages/CategoryList'));
const ProductSearch = lazy(() => import('./pages/ProductSearch'));
//const Product = lazy(() => import("./pages/Product"));
const ResetPasswordRequest = lazy(() => import('./pages/ResetPasswordRequest'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const NotFound = lazy(() => import('./pages/NotFound'));
const ImpersonationHeader = lazy(() => import('components/common/ImeprsonationHeader'));
const Impersonation = lazy(() => import('./pages/Impersonation'));

function App() {
	return (
		<div className="App">
			<Router>
				<HistoryMonitor />
				<PageHeaderMeta />
				<ImpersonationHeader />

				<Suspense fallback={<Loader isLoading={true} />}>
					<Routes>
						<Route path="/login" element={<Login />} />
						<Route path="/logout" element={<Logout />} />
						<Route path="/reset-password-request" element={<ResetPasswordRequest />} />
						<Route path="/reset-password/:guid" element={<ResetPassword />} />
						<Route path="/impersonate/:guid" element={<Impersonation />} />

						<Route element={<ProtectedRoute />}>
							<Route index element={<Home />} />

							<Route path="/pages/:eurl" element={<ContentPage />} />
							<Route path="/categories/:eurl" element={<CategoryList />} />

							<Route path="/search/:keyword" element={<ProductSearch />} />
							<Route path="/all-products" element={<ProductSearch />} />
							<Route path="/status/:sturl" element={<ProductSearch />} />

							{/*<Route path="/products/:eurl" element={<Product />} />*/}

							<Route path="/order-created" element={<OrderCreated />} />
							<Route path="/order-saved" element={<OrderSaved />} />
							<Route path="/order-details" element={<OrderDetails />} />
							<Route path="/checkout" element={<Checkout />} />
							<Route path="/user-dashboard" element={<UserDashboard />} />
						</Route>

						<Route path="*" element={<NotFound />} />
					</Routes>
				</Suspense>
			</Router>

			<ToastContainer />
		</div>
	);
}

export default App;
