import React from 'react';
import PropTypes from 'prop-types';

import Loading from '../../images/loading.gif';

const Loader = props => {
	let loaderClass = 'loader';

	if (props.isLoading) {
		loaderClass += ' visible';
	}

	return <img src={Loading} alt="" width="150" height="40" className={loaderClass} />;
};

Loader.propTypes = {
	isLoading: PropTypes.bool
};

export default Loader;
