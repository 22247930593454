export const loginAction = args => {
	return {
		type: 'login',
		args
	};
};

export const loginSuccessAction = (customer, message) => {
	return {
		type: 'login-success',
		customer,
		message
	};
};

export const logoutAction = () => {
	return {
		type: 'logout'
	};
};

export const logoutSuccessAction = () => {
	return {
		type: 'logout-success'
	};
};

export const authErrorAction = error => {
	return {
		type: 'auth-error',
		error
	};
};

export const clearAuthMessagesAction = () => {
	return {
		type: 'auth-clear-messages'
	};
};
