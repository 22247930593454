import LZString from 'lz-string';

// ShopCart object defined as a closure wrapper
var ShopCart = (function () {
	var _items = [];
	var _batchUpdate = false;

	var startBatchUpdate = function () {
		_batchUpdate = true;
	};

	var stopBatchUpdate = function () {
		_batchUpdate = false;
		internalStoreItems(_items);
	};

	var internalGetStoreItems = function () {
		var buffer = localStorage.getItem('shopcart_items');

		if (buffer != null && buffer.length > 0) {
			if (buffer.startsWith('[')) {
				return buffer;
			}

			return LZString.decompressFromUTF16(buffer);
		}

		return null;
	};

	var internalStoreItems = function (items) {
		if (items != null) {
			var compressed = LZString.compressToUTF16(JSON.stringify(items));
			localStorage.setItem('shopcart_items', compressed);
			_items = items;
		} else {
			localStorage.removeItem('shopcart_items');
			_items = [];
		}
	};

	var getEditOrderId = function () {
		try {
			var order = getEditOrder();

			if (order == null) return 0;

			return order.id;
		} catch (e) {
			console.log(e);
			return 0;
		}
	};

	var getEditOrder = function () {
		try {
			return JSON.parse(localStorage.getItem('shopcart_edit_order'));
		} catch (e) {
			console.log(e);
			return null;
		}
	};

	var setEditOrder = function (order) {
		try {
			localStorage.setItem('shopcart_edit_order', JSON.stringify(order));
		} catch (e) {
			console.log(e);
		}
	};

	var isEmpty = function () {
		var items = getItems();
		return items.length == 0;
	};

	var getItems = function () {
		try {
			if (_items == null || _items.length == 0) {
				_items = [];

				var res = JSON.parse(internalGetStoreItems());

				for (var i in res) {
					// Do not add items that have invalid quantity
					if ((res[i].quantity ?? 0) > 0) {
						_items.push(res[i]);
					}
				}
			}

			return _items;
		} catch (e) {
			console.log(e);
			return [];
		}
	};

	var getSortedItems = function () {
		var items = getItems();

		try {
			// Sort cartItems based on the 'rcnm' property (root category)
			const sortedArray = items.slice().sort((a, b) => {
				const rcnmA = a.rcnm != null ? a.rcnm.toUpperCase() : ''; // Convert to uppercase for case-insensitive sorting
				const rcnmB = b.rcnm != null ? b.rcnm.toUpperCase() : '';

				if (rcnmA < rcnmB) {
					return -1;
				}
				if (rcnmA > rcnmB) {
					return 1;
				}
				return 0;
			});

			return sortedArray;
		} catch (e) {
			console.log(e);
			return [];
		}
	};

	var clear = function () {
		try {
			internalStoreItems(null);

			localStorage.removeItem('shopcart_edit_order');

			//// Remove per user cart also
			//let userId = localStorage.getItem("usr_fe_uid");

			//if (userId != null && userId > 0) {
			//    let key = "shopcart_items_" + userId;
			//    localStorage.removeItem(key);
			//}
		} catch (e) {
			console.log(e);
		}
	};

	var find = function (id) {
		var items = getItems();

		for (let item of items) {
			if (item.id == id) {
				return item;
			}
		}

		return null;
	};

	var addOrUpdate = function (token) {
		try {
			var items = getItems();
			var found = false;

			for (let item of items) {
				if (item.pid == token.pid && item.did == token.did) {
					item.quantity = token.quantity;
					found = true;
					break;
				}
			}

			if (!found) {
				items.push(token);
			}

			if (!_batchUpdate) {
				internalStoreItems(items);
			}

			return true;
		} catch (e) {
			console.log(e);
			return false;
		}
	};

	var add = function (token) {
		try {
			var items = getItems();
			var found = false;

			for (let item of items) {
				if (item.pid == token.pid && item.did == token.did) {
					item.quantity += token.quantity;
					found = true;
					break;
				}
			}

			if (!found) {
				items.push(token);
			}

			if (!_batchUpdate) {
				internalStoreItems(items);
			}

			return true;
		} catch (e) {
			console.log(e);
			return false;
		}
	};

	var remove = function (token) {
		try {
			var items = getItems();
			let filtered = items.filter(item => item.bcd != token.bcd && item.did != token.did);

			if (filtered.length != items.length) {
				internalStoreItems(filtered);

				return filtered;
			}
		} catch (e) {
			console.log(e);
		}

		return false;
	};

	var removeLine = function (pid) {
		try {
			var items = getItems();
			let filtered = items.filter(item => item.pid != pid);

			if (filtered.length != items.length) {
				internalStoreItems(filtered);

				return filtered;
			}
		} catch (e) {
			console.log(e);
		}

		return false;
	};

	var removeDimensionLine = function (did) {
		try {
			var items = getItems();
			let filtered = items.filter(item => item.did != did);

			if (filtered.length != items.length) {
				internalStoreItems(filtered);

				return filtered;
			}
		} catch (e) {
			console.log(e);
		}

		return false;
	};

	var setDBCart = function (items) {
		try {
			internalStoreItems(items);

			return true;
		} catch (e) {
			console.error(e);
			return false;
		}
	};

	var getTotalQuantity = function (pid) {
		try {
			var items = getItems();
			var totalQuantity = 0;

			if (items.length > 0) {
				if (pid != null) {
					items.filter(item => item.pid == pid).forEach(item => (totalQuantity += item.quantity));
				} else {
					items.forEach(item => (totalQuantity += item.quantity));
				}
			}

			return totalQuantity;
		} catch (e) {
			console.log(e);
			return 0;
		}
	};

	var getTotalQuantityByBarcode = function (bcd) {
		try {
			var items = getItems();
			var totalQuantity = 0;

			if (items.length > 0) {
				if (bcd != null) {
					items.filter(item => item.bcd == bcd).forEach(item => (totalQuantity += item.quantity));
				} else {
					items.forEach(item => (totalQuantity += item.quantity));
				}
			}

			return totalQuantity;
		} catch (e) {
			console.log(e);
			return 0;
		}
	};

	var getTotalAmount = function (pid) {
		try {
			var items = getItems();
			var totalAmount = 0;

			if (items.length > 0) {
				if (pid != null) {
					items.filter(item => item.pid == pid).forEach(item => (totalAmount += item.amount * item.quantity));
				} else {
					items.forEach(item => (totalAmount += item.amount * item.quantity));
				}
			}

			return totalAmount;
		} catch (e) {
			console.log(e);
			return 0;
		}
	};

	var getTotalAmountByBarcode = function (bcd) {
		try {
			var items = getItems();
			var totalAmount = 0;

			if (items.length > 0) {
				if (bcd != null) {
					items.filter(item => item.bcd == bcd).forEach(item => (totalAmount += item.amount * item.quantity));
				} else {
					items.forEach(item => (totalAmount += item.amount * item.quantity));
				}
			}

			return totalAmount;
		} catch (e) {
			console.log(e);
			return 0;
		}
	};

	var getDimTotalQuantity = function (id) {
		try {
			var items = getItems();
			var totalQuantity = 0;

			if (items.length > 0) {
				items.filter(item => item.did == id).forEach(item => (totalQuantity += item.quantity));
			}

			return totalQuantity;
		} catch (e) {
			console.log(e);
			return 0;
		}
	};

	var getTotalsByRootCategory = function (id) {
		var nm = '';
		var qty = 0;
		var amt = 0;
		var amt2 = 0;
		var perc = 0;

		try {
			var items = getItems();
			var totalQty = 0;

			if (items.length > 0) {
				items.forEach(item => (totalQty += item.quantity));

				items
					.filter(item => item.rcid == id)
					.forEach(item => {
						nm = item.rcnm;
						qty += item.quantity;
						amt += item.amount * item.quantity;
						amt2 += item.amount2 * item.quantity;
					});

				if (totalQty > 0) {
					perc = (qty / totalQty) * 100.0;
				}
			}
		} catch (e) {
			console.log(e);
		}

		return {
			id: id,
			name: nm,
			quantity: qty,
			amount: amt,
			amount2: amt2,
			participation: perc
		};
	};

	var getZeroQuantityItems = function () {
		try {
			var items = getItems();

			if (items.length == 0) return [];

			// We need to check if entire ID is empty and not if one particular dimension has 0 quantity.
			var idQuantityMap = items.reduce(function (acc, item) {
				if (item.singleBC) {
					// work with saled products, use dimemsionID as key.
					if (acc[item.did] === undefined) {
						acc[item.did] = 0;
					}
					acc[item.did] += item.quantity;
				} else {
					if (acc[item.id] === undefined) {
						acc[item.id] = 0;
					}
					acc[item.id] += item.quantity;
				}

				return acc;
			}, {});

			var resultsArr = items.filter(function (item) {
				return idQuantityMap[item.id] === 0 || idQuantityMap[item.did] === 0;
			});

			return resultsArr;
		} catch (e) {
			console.log(e);
			return [];
		}
	};

	var getNonSingleBCQuantity = function () {
		try {
			var items = getItems();
			var totalQuantity = 0;

			if (items.length > 0) {
				items.filter(item => item.singleBC == null).forEach(item => (totalQuantity += item.quantity));
			}

			return totalQuantity;
		} catch (e) {
			console.log(e);
			return 0;
		}
	};

	var storeCartForUser = function (id) {
		try {
			let key = 'shopcart_items_' + id;
			let cartData = internalGetStoreItems();

			if (cartData != null && cartData.length > 0) {
				var compressed = LZString.compressToUTF16(JSON.stringify(cartData));
				localStorage.setItem(key, compressed);
			} else {
				localStorage.removeItem(key);
			}
		} catch (e) {
			console.log(e);
		}
	};

	var restoreCartForUser = function (id) {
		try {
			let key = 'shopcart_items_' + id;
			let cartData = localStorage.getItem(key);

			if (cartData != null && cartData.length > 0) {
				if (cartData.startsWith('[')) {
					internalStoreItems(JSON.parse(cartData));
				} else {
					var uncompressed = LZString.decompressFromUTF16(cartData);
					if (uncompressed.startsWith('[')) {
						internalStoreItems(JSON.parse(uncompressed));
					} else {
						internalStoreItems(null);
					}
				}
			} else {
				internalStoreItems(null);
			}
		} catch (e) {
			console.log(e);
		}
	};

	return {
		getEditOrderId,
		getEditOrder,
		setEditOrder,
		isEmpty,
		getItems,
		getSortedItems,
		clear,
		find,
		add,
		remove,
		removeLine,
		removeDimensionLine,
		addOrUpdate,
		getTotalQuantity,
		getTotalQuantityByBarcode,
		getTotalAmount,
		getTotalAmountByBarcode,
		getDimTotalQuantity,
		setDBCart,
		getTotalsByRootCategory,
		getZeroQuantityItems,
		getNonSingleBCQuantity,
		storeCartForUser,
		restoreCartForUser,
		startBatchUpdate,
		stopBatchUpdate,
		internalStoreItems
	};
})();

export default ShopCart;
