import { combineReducers } from 'redux';

// Combine all reducers in here

// Auth
import authReducer from 'store/auth/reducer';

// Checkout
import checkoutReducer from 'store/checkout/reducer';

// History
import historyReducer from 'store/history/reducer';

// History
import filtersReducer from 'store/filters/reducer';

const rootReducer = combineReducers({
	authReducer,
	checkoutReducer,
	historyReducer,
	filtersReducer
});

export default rootReducer;
