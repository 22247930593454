const initialState = {
	url: '',
	availability: -1,
	loading: false
};

const filtersReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'flt-apply':
			state = { ...state, url: action.args, loading: true };
			break;
		case 'flt-reset':
			state = { ...state, url: '', loading: false };
			break;
		case 'flt-availability':
			state = { ...state, availability: action.args, loading: false };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default filtersReducer;
