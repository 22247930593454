import React from 'react';
import { toast } from 'react-toastify';

export const notifyError = (message, closeAction = null) => {
	//// Remove all toasts !
	//toast.dismiss();

	toast.error(message, {
		position: toast.POSITION.TOP_CENTER,
		toastId: 'nfyRegisterApiError-' + Math.random(),
		onClose: closeAction
	});
};

export const notifyInfo = (message, closeAction = null) => {
	//// Remove all toasts !
	//toast.dismiss();

	toast.info(message, {
		position: toast.POSITION.TOP_CENTER,
		toastId: 'nfyRegisterMsg-' + Math.random(),
		onClose: closeAction
	});
};

export const clearNotifications = () => {
	toast.dismiss();
};
